import { Component, OnInit } from '@angular/core';
import { DocumentsState, GetAllDocuments } from '../../states/documents.state';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { Document } from 'src/app/core/models/document.model';
import { DocumentService } from 'src/app/core/services/document.service';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
    selector: 'app-files',
    templateUrl: './files.component.html',
    styleUrls: ['./files.component.scss'],
})
export class FilesComponent implements OnInit {
    @Select(DocumentsState.documents) documents$: Observable<Document[]> | undefined;
    constructor(
        private store: Store,
        private documentService: DocumentService,
        private toastService: ToastService,
    ) {}

    isUploading = false;
    folderName = 'myFolder';
    documentsHeader = [
        { key: 'folder', label: 'Dossier' },
        { key: 'name', label: 'Nom du fichier' },
    ];

    documentsActions = [
        {
            label: 'Télécharger',
            onClick: (doc: Document) => {
                return this.download(doc);
            },
        },
        {
            label: 'Supprimer',
            onClick: (doc: Document) => {
                return this.delete(doc);
            },
        },
    ];

    ngOnInit() {
        this.getAllDocuments();
    }

    async getAllDocuments(): Promise<void> {
        this.store.dispatch(new GetAllDocuments({ folderName: this.folderName }));
    }
    async onFileUpload(file: File) {
        this.isUploading = true;
        try {
            const newDoc: Document = await this.documentService.upload(this.folderName, file);
            this.toastService.showSuccessToast(`Le fichier "${newDoc.name}" a bien été enregistré`);
        } catch (err) {
            this.toastService.showSuccessToast(
                `Une erreur s'est produite : Le fichier "${file.name}" n'a pas pu être enregistré`,
            );
            console.log(err);
        } finally {
            this.isUploading = false;
            this.getAllDocuments();
        }
    }
    async download(doc: Document) {
        return this.documentService.get(doc.id).subscribe((blob: any) => {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = doc.name;
            a.click();
            URL.revokeObjectURL(objectUrl);
        });
    }
    async delete(doc: Document) {
        try {
            await this.documentService.delete(doc.id);
            this.toastService.showSuccessToast(`Le fichier "${doc.name}" a bien été supprimé`);
        } catch (e) {
            this.toastService.showSuccessToast(
                `Une erreur s'est produite : Le fichier "${doc.name}" n'a pas pu être enregistré`,
            );
            throw e;
        } finally {
            this.getAllDocuments();
        }
    }
}
