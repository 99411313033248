
<div class="container mt-5 login d-flex justify-content-center">
    <div class="col-4 justify-content-center mt-4">
        <ng-container *ngIf="!emailSentTo; else emailSentBlock">
                <div class="text-center">
                    <h3>Mot de passe oublié ?</h3>
                    <div class="subtitle mb-3">Renseignez votre email et nous vous enverrons des instructions pour le réinitialiser</div>
                </div>
                <form (ngSubmit)="onSubmit()">
                <div class="form-group mb-3">
                    <edInput
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    [required]="true"
                    [(ngModel)]="email"
                    [isInvalid]="!!errorMessage"
                ></edInput>
                </div>
                <div class="button-group">
                    <ed-button [type]="ButtonTypes.Submit"
                            [disabled]="forgotPasswordFormGroup.invalid">Réinitialiser mon mot de passe</ed-button>
                </div>
                </form>
        </ng-container>
    
        <ng-template #emailSentBlock>
            <h1>Consultez vos emails</h1>
            <div class="subtitle">Si cet email est lié à un utiliseur, vous allez recevoir un lien de réinitialisation de votre mot de passe à cette adresse <br><b>{{ emailSentTo }}</b></div>
            <div class="email-info">Email non reçu ? <a (click)="onSubmit()">Cliquez pour renvoyer</a></div>
        </ng-template>
        
        <div class="mt-2">
            <a routerLink="/"><i class="icon icon-arrow-left"></i>Se connecter</a>
        </div>
    </div>
</div>