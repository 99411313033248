


<div class="container mt-5 login d-flex justify-content-center">
  <div class="col-4 justify-content-center mt-4">
    <ng-container *ngIf="!passwordChanged; else passwordChangedBlock">
      <h1>Nouveau mot de passe</h1>
      <div class="subtitle">Choisissez un nouveau mot de passe unique et gardez le précieusement.</div>

      <form (ngSubmit)="onSubmit()"
            >
        <div class="form-group">
            <edInput
              id="password"
              name="password"
              type="password"
              placeholder="Mot de passe"
              [required]="true"
              [minlength]="7"
              [(ngModel)]="password"
              [isInvalid]="!!errorMessage"
            ></edInput>
        </div>
        <div class="button-group">
          <ed-button [type]="ButtonTypes.Submit"
                    [disabled]="changePasswordFormGroup.invalid">Réinitialiser mon mot de passe</ed-button>
        </div>
      </form>

      <div class="back">
        <a routerLink="/"><i class="icon icon-arrow-left"></i>Se connecter</a>
      </div>
    </ng-container>

    <ng-template #passwordChangedBlock>
      <h1>Mot de passe enregistré</h1>
      <div class="subtitle">Votre nouveau mot de passe a été enregistré.<br>Vous pouvez dès à présent vous connecter.</div>
      <ed-button class="full-width" routerLink="/">Se connecter</ed-button>
    </ng-template>

  </div>
</div>


