<div class="page">
    <div class="page-title">Fichiers</div>
    <div class="page-description">Page de gestion des fichiers</div>

    <div class="page-content">
        <edTable
        [headers]="documentsHeader"
        [data]="(documents$ | async)"
        [actions]="documentsActions">
    </edTable> 

    <div>
        <ed-input-file
            id="edInputFileShowCase"
            [allowedFormat]="['SVG', 'PNG', 'JPG', 'GIF']"
            [isUploading]="isUploading"
            (onSubmit)="onFileUpload($event)"></ed-input-file>
    </div>
  </div>
</div>

