import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertService, ButtonTypes } from '@ed---interne/ng-uui-components';
import { Store } from '@ngxs/store';
import { PasswordChange } from '../core/states/auth.state';

@Component({
    selector: 'cil-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
    public ButtonTypes = ButtonTypes;
    password = '';
    errorMessage = '';
    public changePasswordFormGroup: FormGroup = this.formBuilder.group({
        password: [''],
    });

    public passwordChanged = false;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private store: Store,
    ) {}

    public onSubmit(): void {
        const token = this.activatedRoute.snapshot.queryParams['token'] || '';

        this.store.dispatch(
            new PasswordChange({
                token: token,
                password: this.password,
            }),
        );
        this.passwordChanged = true;
    }
}
