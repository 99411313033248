<div class="container mt-5 login">
    <div class="row justify-content-center">
        <div class="text-center">
            <i class="pe-1 bi bi-stars"></i>
            <h3>Se connecter</h3>
            <p>Bienvenue | Entrez votre mail et mot de passe</p>
        </div>
        <form
            class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12"
            #loginForm="ngForm"
            (ngSubmit)="login(loginForm)"
        >
            <label for="email" class="form-label">Email</label>
            <edInput
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                [required]="true"
                [(ngModel)]="email"
                [isInvalid]="!!errorMessage"
            ></edInput>
            <label for="password" class="form-label">Mot de passe</label>
            <edInput
                id="password"
                name="password"
                type="password"
                placeholder="Mot de passe"
                [required]="true"
                [minlength]="7"
                [(ngModel)]="password"
                [isInvalid]="!!errorMessage"
            ></edInput>
            <ed-button
                [type]="ButtonTypes.Submit"
                fullWidth
                [disabled]="!loginForm.form.valid"
            >Connexion</ed-button>
            <span role="alert" class="text-danger" *ngIf="errorMessage">
                {{ errorMessage }}
            </span>
            <span role="alert" class="text-danger" *ngIf="error$ | async; let error">
                {{ error?.message }}
            </span>
            <span class="mt-2">
                <i class="icon icon-key-01"></i><a routerLink="/forgot-password" href="/forgot-password">Mot de passe oublié</a>
            </span>
            
        </form>

        


        <span><small><b>Utilisateur admin :</b> admin&#64;ed.fr | ed4you;</small></span>
        <span><small><b>Utilisateur normal :</b> user&#64;ed.fr | ed4you;</small></span>
        <span><small><b>Utilisateur désactivé :</b> disabled&#64;ed.fr | ed4you;</small></span>
    </div>
</div>
