import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { NgxsModule } from '@ngxs/store';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AuthState } from './core/states/auth.state';
import { AdminUsersState } from './main/states/adminUsers.state';
import { initializeAppFactory } from './app.initializer';
import { AuthService } from './core/services/auth.service';
import { AlertModule, TextfieldModule } from '@ed---interne/ng-uui-components';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { DocumentsState } from './main/states/documents.state';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        CoreModule,
        SharedModule,
        TextfieldModule,
        AlertModule,
        NgxsModule.forRoot([AuthState, AdminUsersState, DocumentsState]),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [AuthService, Sentry.TraceService],
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        provideEnvironmentNgxMask(),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
